<template>
  <v-app>
    <v-app-bar dark app v-bind:src="bannerImage">
      <v-toolbar-title>
        Narwhal - Qu-Bit Module Config
      </v-toolbar-title>
      <template v-slot:extension>
        <v-row class="mx-4" v-cloak @drop.prevent="addDropFile" @dragover.prevent>
          <v-file-input class="mx-4" id="user-settings" accept=".json" label="load from options.json" v-model="userFile"
            @change="import_settings"></v-file-input>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn class="mx-4" depressed color="secondary"
          @click="restore_defaults"><v-icon>mdi-restore</v-icon>Defaults</v-btn>
        <v-btn class="mx-4" depressed color="primary" @click="generate"><v-icon>mdi-download</v-icon>Download</v-btn>
        <v-btn class="mx-2" small fab color="blue" @click="toggleDark"><v-icon>mdi-theme-light-dark</v-icon></v-btn>
      </template>
    </v-app-bar>
    <v-main>
      <v-container id="config-section">
        <v-spacer></v-spacer>
        <v-row>
          <v-card height=100% elevation="9">
            <v-card-title>Select Module</v-card-title>
            <v-card-actions>
              <v-select v-model="currentModule" :items="Object.keys(modules)" @change="updateOptions"></v-select>
            </v-card-actions>
          </v-card>
        </v-row>
        <v-row>
          <v-col v-for="option in options" :key="option.name" cols="4">
            <v-card height=100% elevation="9">
              <v-card-title>{{ option.name }}</v-card-title>
              <v-card-text>{{ option.help }}</v-card-text>
              <v-card-actions v-if="option.type === 'bool'" class="align-center justify-center">
                <v-switch v-model="option.value" :id="option.name" class="align-center justify-center" hide-details
                  inset></v-switch>
              </v-card-actions>
              <v-card-actions v-else-if="option.type === 'float'">
                <v-slider v-model="option.value" :min="option.min" :max="option.max" :id="option.name"
                  :step="option.step">
                  <template v-slot:append>
                    <v-text-field v-model="option.value" class="mt-0 pt-0" hide-details single-line type="number"
                      outlined style="width: 85px"></v-text-field>
                  </template>
                </v-slider>
              </v-card-actions>
              <v-card-actions v-else-if="option.type === 'choice'">
                <v-select v-model="option.value" :name="option.name" :id="option.name" :items="option.choices">
                </v-select>
              </v-card-actions>
              <v-card-actions class="align-center justify-center" v-else-if="option.type === 'color'">
                <v-color-picker hide-canvas v-model="option.value" :id="option.name"></v-color-picker>
              </v-card-actions>
              <v-card-actions v-else>
                <p>Option type not supported...</p>
              </v-card-actions>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn depressed color="primary" @click="generate" width="100%">Download File</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import optionsData from "./module_options.json"

export default {
  name: 'ModuleConfigurator',
  props: {},
  data() {
    return {
      options: optionsData.Stardust.options,
      modules: optionsData,
      userFile: null,
      fileUploadErr: null,
      currentModule: "Stardust",
      bannerImage: optionsData.Stardust.module_data.banner_image
    }
  },
  mounted() {
    // component is now mounted
    // read in the json
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.$vuetify.theme.dark = true
    }

  },
  methods: {
    generate() {
      let testdata = this.options
      let module_name = optionsData[this.currentModule].module_data.firmware_id
      // real quick make a mini version
      let outdata = {}
      outdata[module_name] = []
      testdata.forEach(item => outdata[module_name].push({ "name": item.name, "value": item.value }))
      let filename = "options.json";
      let text = JSON.stringify(outdata, null, 2);
      let element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    restore_defaults() {
      this.options.forEach(item => item.value = item.default)
    },
    import_settings() {
      this.fileUploadErr = null;
      const reader = new FileReader();
      if (this.userFile) {
        if (this.userFile.name.includes(".json")) {
          reader.onload = (res) => {
            // now we actually want to do the stuff
            let obj = JSON.parse(res.target.result);
            if (obj.nautilus) {
              let opts = obj.nautilus
              let local_data = this.options
              // this is slow, but whatevs
              for (let i = 0; i < opts.length; i++) {
                let key = opts[i].name
                // find obj in local data
                for (let j = 0; j < this.options.length; j++) {
                  let d = local_data[j];
                  if (d.name == key) {
                    // update data
                    // TODO: add validation
                    d.value = opts[i].value
                  }
                }
              }
            }
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.userFile);
        } else {
          this.fileUploadErr = "check the console for file output";
          reader.onload = (res) => {
            console.log(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.file);
        }
      }
    },
    addDropFile(e) {
      this.userFile = e.dataTransfer.files[0]
      this.import_settings()
    },
    toggleDark() {
      if (this.$vuetify.theme.dark)
        this.$vuetify.theme.dark = false;
      else
        this.$vuetify.theme.dark = true;
    },
    updateOptions() {
      this.options = this.modules[this.currentModule].options
      this.bannerImage = this.modules[this.currentModule].module_data.banner_image
    }
  }
}
</script>

<style>
.ocean {
  background: url('./img/ocean_big_square.png') no-repeat center center fixed !important;
  background-size: cover;
}
</style>